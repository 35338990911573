import React from "react"
import "../styles/contactPage.scss"
// import { Link } from "gatsby"

import SEO from "../components/seo"


const description = "Tapicer meblowy, medyczny, samochodowy i jachtowy. Profesjonalne usługi kuśnierskie, kaletnicze, rymarskie i krawieckie. Konin"
const keywords = ["kontakt kuśnierz konin", "kontakt tapicer konin"]


const Contact = () => (
    <>
        <SEO
            title="Kontakt"
            description={description}
            keywords={keywords}

        />
        <div className="contactPage container">
            <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2445.322001136506!2d18.23536831568441!3d52.201195679755116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471b31644888a167%3A0x24f07d52a27b93c8!2sKu%C5%9Bnierz%20-%20Tapicer!5e0!3m2!1spl!2spl!4v1607699990981!5m2!1spl!2spl" width="1200" height="640" frameborder="0" style={{ margin: "0 auto" }} allowfullscreen="" aria-hidden="false" title="mapa kuśnierz Konin"></iframe>
            </div>

        </div>
    </>
)



export default Contact
